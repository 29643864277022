<template>
    <div class="wrapper" v-loading="loading">
        <div v-if="!orgId" class="top-btn" ref="topBtn">
            <div>
                <el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"> </el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入内容" v-model="search.desp" clearable> </el-input>
                <el-button size="small" type="primary" @click="searchList()">搜索</el-button>
            </div>
            <div>
                <el-select class="w110" size="small" v-model="search.admin_status" clearable @change="searchList()">
					<el-option :key="0" :value="0" label="禁用"></el-option>
					<el-option :key="1" :value="1" label="正常"></el-option>
				</el-select>
                <el-select class="w110" size="small" v-model="search.status" clearable @change="searchList()">
					<el-option :key="0" :value="0" label="下架"></el-option>
					<el-option :key="1" :value="1" label="上架"></el-option>
				</el-select>
                <el-button size="small" type="primary" @click="add()">添加</el-button>
            </div>
        </div>
        <el-table
            :data="list"
            :max-height="tableHeight"
            :header-cell-style="{background:'#f5f7fa'}"
            style="width: 100%">
            <el-table-column prop="id" label="试听课id"></el-table-column>
            <el-table-column prop="name" label="课程"></el-table-column>
            <el-table-column label="试听课封面">
                <template slot-scope="scope"> 
                   <img :src="scope.row.course_cover || ''" style="width: 50px" alt=""/>
                </template>
            </el-table-column>
            <el-table-column prop="org_price" label="划线价"></el-table-column>
            <el-table-column prop="price" label="价格"></el-table-column>
            <el-table-column prop="org_name" label="机构"></el-table-column>
            <el-table-column prop="org_adress" label="机构城市"></el-table-column>
            <el-table-column prop="cates" label="分类"></el-table-column>
            <el-table-column label="数据汇总">
                <template slot-scope="scope"> 
                   已售{{ scope.row.sale_num || '--' }} &nbsp;&nbsp; 
                   分享{{ scope.row.share_num || '--' }} &nbsp;&nbsp;
                   问答{{ scope.row.q7a_num || '--' }} &nbsp;&nbsp; 
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                   {{ scope.row.admin_status == 0 ? '禁用' : 
                   scope.row.admin_status == 1 ? '正常' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="上下架状态">
                <template slot-scope="scope">
                   {{ scope.row.status == 0 ? '下架' : 
                   scope.row.status == 1 ? '上架' : '--' }}
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="{fun: 'handleUp',row: scope.row, change_field: 'admin_status'}">{{ scope.row.admin_status != 1 ? '启用' : '禁用' }}</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleUp',row: scope.row, change_field: 'status'}">{{ scope.row.status != 1 ? '上架' : '下架' }}</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleEdit',row: scope.row.id}">编辑</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleDetail',row: scope.row.id}">详情</el-dropdown-item>
                            <el-dropdown-item :command="{fun: 'handleDelete',row: scope.row.id}">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            v-if="total > 0"
            style="padding-top: 15px; text-align: center;"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="limit"
            layout="prev, pager, next, jumper"
            :total="total"
            >
        </el-pagination>
        
        <stkAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :id="id" @close="close" @getList="getList" @searchList="searchList"></stkAdd>
        <stkDetail ref="detail" :dialogVisible="dialogVisible2" :id="id" @close="close2"></stkDetail>
    </div>
</template>
<script>
import { courseList, onOrOffCourse, delCourse } from '@/api/courseOrder'
import stkAdd from './component/stkAdd.vue'
import stkDetail from './component/stkDetail.vue'
export default {
    name: "StdList",
    props: {
        orgId: { // 机构详情里
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: true,
            tableHeight: this.$constant.maxHeight,
            // 列表
            searchGist:  [{
                value: 'id',
                label: '课程id'
            }, {
                value: 'course_name',
                label: '课程名'
            }, {
                value: 'org_name',
                label: '机构名'
            }],
            // 列表
            search: {
                search_gist: 'course_name',
                desp: '',
                admin_status: '',
                status: ''
            },
            total: 0,
            limit: 20,
            currentPage: 1,
            list: [],
            // 弹框
            dialogVisible: false,
            id: 0,
            typeFlag: 'add',
            // 详情弹框
            dialogVisible2: false,
        }
    },
    components: {
        stkAdd,
        stkDetail
    },
    mounted() {
        this.getList()
        this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        window.onresize = this.$common.debounce(()=>{
            this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
        }, 1000)
    },
    methods: {
        // 获取列表
        getList() {
            let data = {}
            if(this.orgId) {
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    org_id: this.orgId
                }
            }else {
                data = {
                    limit: this.limit,
                    page: this.currentPage,
                    id: this.search.search_gist == 'id' ? this.search.desp : null,
                    course_name: this.search.search_gist == 'course_name' ? this.search.desp : null,
                    org_name: this.search.search_gist == 'org_name' ? this.search.desp : null,
                    admin_status: this.search.admin_status,
                    status: this.search.status,
                }
            }
            courseList(data).then((res) => {
                this.total = res.data.total;
                this.list = res.data.data;
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
        },
        // 搜索
        searchList() {
            this.loading = true
            this.currentPage = 1;
            this.getList();
        },
        // 页码修改
        handleCurrentChange(val) {
            this.loading = true
            this.currentPage = val;
            this.getList();
        },
        // 新建
        add(){
            this.typeFlag = 'add'
            this.dialogVisible = true
        },
        // 操作回调
        handleCommand(command) {
            this[command.fun](command.row,command.change_field)
        },
        // 编辑
        handleEdit(id) {
            this.$refs.add.getInfo(id)
            this.id = id
            this.typeFlag = 'edit'
            this.dialogVisible = true
        },
        // 详情
        handleDetail(id) {
            this.$refs.detail.getInfo(id)
            this.id = id
            this.dialogVisible2 = true
        },
        // 删除
        handleDelete(id) {
            this.$confirm('确定删除吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
               this.deleteFun(id)
            })
            .catch(() => {});
        },
        // 删除
        deleteFun(id){
            delCourse({ id }).then(()=>{
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                this.searchList()
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 上下架
        handleUp(row, change_field){
			let _this = this
            let content = '', change_to = ''
            if(change_field == 'status') { // 上下架
                content = row.status != 1 ? '上架' : '下架'
                change_to = row.status != 1 ? 1 : 0
            }else { // 是否禁用
                content = row.admin_status != 1 ? '启用' : '禁用'
                change_to = row.admin_status != 1 ? 1 : 0
            }
			this.$alert(`是否确定${content}`, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                    if(action == 'confirm'){
                        onOrOffCourse({
                            id: row.id,
                            change_field,
                            change_to
                        }).then((res)=>{
                            if(res.code == 200) {
                                _this.$message({
                                    message: content + '成功',
                                    type: 'success'
                                });
                                _this.getList()
                            }
                        }).catch((err)=>{
                            console.log(err)
                        })
                    }
                }
			});
            
        },
        // 关闭弹框
        close() {
            this.dialogVisible = false
        },
        close2() {
            this.dialogVisible2 = false
        },
    }
};
</script>
