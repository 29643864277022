<template>
	<div class="dialog">
		<el-dialog title="详情" :visible.sync="dialogVisible" width="900px" :before-close="close" :close-on-click-modal='false'>
			<div style="display:flex">
				<div style="width: 40%">
					课程详情：
					<div class="wrap">
						<img class="imgs" src="../../../assets/imgs/phone.png" alt="">
						<div class="imgs-wrap">
							<img v-if="ruleForm.course_cover" style="width: 100%" :src="ruleForm.course_cover" alt="">
							<img style="width: 100%" src="../../../assets/imgs/courseDetail.png">
							<template v-if="ruleForm.course_imgs&&ruleForm.course_imgs.length > 0">
								<img style="width: 100%" v-for="(item,index) in ruleForm.course_imgs" :key="index" :src="item">
							</template>
							<div style="margin:0 20px 20px 20px">{{ruleForm.desp }}</div>
						</div>
					</div>
				</div>
				<el-form :model="ruleForm" label-width="240px" class="demo-ruleForm" style="width: 55%">
					<el-form-item label="机构名称：">
						<div>{{ruleForm.org_name}}</div>
					</el-form-item>
					<el-form-item label="课程名称：">
						<div>{{ruleForm.name}}</div>
					</el-form-item>
					<el-form-item label="划线价：">
						<div>{{ruleForm.org_price}}</div>
					</el-form-item>
					<el-form-item label="课程价格：">
						<div>{{ruleForm.price}}</div>
					</el-form-item>
					<el-form-item label="分类：">
						<div>{{ruleForm.cates ? ruleForm.cates.join('') : ''}}</div>
						</el-form-item>
					<el-form-item label="授课方式：">
						<div>{{ruleForm.teaching_method}}</div>
					</el-form-item>
					<el-form-item label="适用年龄：">
						<div>{{ruleForm.for_age}}</div>
					</el-form-item>
					<el-form-item label="体验时长：">
						<div>{{ruleForm.mins}}</div>
					</el-form-item>
					<el-form-item label="体验次数：">
						<div>{{ruleForm.exp_count}}</div>
					</el-form-item>
					<template v-if="ruleForm.group_switch">
						<el-form-item label="成团人数：">
							<div>{{ruleForm.group_people_num}}</div>
						</el-form-item>
						<el-form-item label="开团价格：">
							<div>{{ruleForm.group_open_price}}</div>
						</el-form-item>
						<el-form-item label="参团价格：">
							<div>{{ruleForm.group_price}}</div>
						</el-form-item>
						<el-form-item label="拼团时间：">
							<div>{{ruleForm.group_start_at*1000 | formatDate}} - {{ruleForm.group_end_at*1000 | formatDate}}</div>
						</el-form-item>
						<el-form-item label="课程封面：">
							<img :src="ruleForm.course_cover" alt="" style="width: 100px">
						</el-form-item>
						<el-form-item label="活动到期，未完成团自动成团：">
							<div>{{ruleForm.group_auto_ok ? '是' : '否'}}</div>
						</el-form-item>
						<el-form-item label="拼团支持红包抵用金付款：">
							<div>{{ruleForm.group_credit ? '是' : '否'}}</div>
						</el-form-item>
						<el-form-item label="拼团满团后还可继续参团：">
							<div>{{ruleForm.group_ok_continue ? '是' : '否'}}</div>
						</el-form-item>
						<el-form-item label="拼团支持退款：">
							<div>{{ruleForm.group_refund ? '是' : '否'}}</div>
						</el-form-item>
					</template>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		courseInfo
	} from '@/api/courseOrder'
	export default {
		name: "StkDetail",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				ruleForm: {
					org_id: '',
					org_name: '',
					name: '',
					org_price: '',
					price: '',
					cates: [],
					teaching_method: '',
					for_age:'',
					mins: '',
					exp_count: '',
					course_imgs: [],
					desp: '',
					// 拼团
					group_switch: false,
					group_people_num:'',
					group_open_price:'',
					group_price:'',
					group_start_at: '',
					group_end_at: '',
					course_cover: '',
					group_auto_ok: false,
					group_credit: false,
					group_ok_continue: false,
					group_refund: false,
				},
			}
		},
		mounted() {
		
		},
		methods: {
			// 获取数据
			getInfo(id) {
				courseInfo({
					id: id || this.id
				}).then((res) => {
					for (let item in this.ruleForm) {
						if (item == 'cates') {
							this.ruleForm[item] = res.data[item] ? res.data[item].split(',') : ''
						} else {
							this.ruleForm[item] = res.data[item]
						}
					}
				}).catch((err) => {
					console.log(err, 222)
				})
				this.$emit('close');
			},
			// 关闭
			close() {
				this.$emit('close');
			},
		}
	};
</script>
<style lang="less" scoped>
.wrap {
	position:relative;
	width: 280px;
	height: 560px;
	margin-left: 60px;
	.imgs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.imgs-wrap {
		position: absolute;
		top: 20px;
		left: 21px;
		width: 85%;
		height: 94%;
		border-radius: 24px;
		background: #fff;
		overflow-y: auto;
	}
	::-webkit-scrollbar{width:0;}
}
</style>
