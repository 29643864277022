import { render, staticRenderFns } from "./stkDetail.vue?vue&type=template&id=6ce84582&scoped=true&"
import script from "./stkDetail.vue?vue&type=script&lang=js&"
export * from "./stkDetail.vue?vue&type=script&lang=js&"
import style0 from "./stkDetail.vue?vue&type=style&index=0&id=6ce84582&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce84582",
  null
  
)

export default component.exports