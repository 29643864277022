<template>
  <div class="dialog">
    <el-dialog
      :title="typeFlag == 'add' ? '添加' : '修改'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="240px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="请选择"
          v-if="typeFlag == 'add' && dialogVisible"
          prop="org_id"
        >
          <OrgSelect @selectOrg="selectOrg" />
        </el-form-item>
        <el-form-item label="机构名称" v-else-if="typeFlag == 'edit'">
          <div>{{ org_name }}</div>
        </el-form-item>
        <el-form-item label="课程名称" prop="name">
          <el-input size="small" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="是否可重复购买" prop="repeat_pay">
          <el-switch v-model="ruleForm.repeat_pay"></el-switch>
        </el-form-item>
        <el-form-item label="划线价" prop="org_price">
          <el-input
            size="small"
            type="number"
            v-model="ruleForm.org_price"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程价格" prop="price">
          <el-input
            size="small"
            type="number"
            v-model="ruleForm.price"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="cates">
          <el-checkbox-group v-model="ruleForm.cates">
            <el-checkbox
              v-for="(item, index) in catesList"
              :key="index"
              :label="item.name"
              >{{ item.name || "--" }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="标签">
          <el-checkbox-group v-model="ruleForm.tags" :max="10">
            <el-checkbox
              v-for="(item, index) in tagsList"
              :key="index"
              :label="item.name"
              >{{ item.name || "--" }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="授课方式" prop="teaching_method">
          <el-input size="small" v-model="ruleForm.teaching_method"></el-input>
          <el-tag
            v-for="item in teachingList"
            :key="item"
            size="small"
            @click="ruleForm.teaching_method = item"
            style="margin-right: 10px; cursor: pointer"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="适用年龄" prop="for_age">
          <el-input size="small" v-model="ruleForm.for_age"></el-input>
          <el-tag
            v-for="item in ageList"
            :key="item"
            size="small"
            @click="ruleForm.for_age = item"
            style="margin-right: 10px; cursor: pointer"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="体验时长" prop="mins">
          <el-input
            size="small"
            type="number"
            v-model="ruleForm.mins"
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item label="体验次数" prop="exp_count">
          <el-input
            size="small"
            type="number"
            v-model="ruleForm.exp_count"
            style="width: 100px"
          ></el-input>
        </el-form-item>

        <el-form-item label="开启拼团">
          <el-switch v-model="ruleForm.group_switch"></el-switch>
        </el-form-item>
        <template v-if="ruleForm.group_switch">
          <el-form-item label="成团人数" prop="group_people_num">
            <el-input
              size="small"
              v-model="ruleForm.group_people_num"
              style="width: 100px"
            ></el-input>
            <div>
              <el-tag
                v-for="item in groupNumber"
                :key="item"
                size="small"
                @click="ruleForm.group_people_num = item"
                style="margin-right: 10px; cursor: pointer"
                >{{ item }}</el-tag
              >
            </div>
          </el-form-item>
          <el-form-item label="开团价格" prop="group_open_price">
            <el-input
              size="small"
              type="number"
              v-model="ruleForm.group_open_price"
              style="width: 100px"
            ></el-input>
          </el-form-item>
          <el-form-item label="参团价格" prop="group_price">
            <el-input
              size="small"
              type="number"
              v-model="ruleForm.group_price"
              style="width: 100px"
            ></el-input>
            <div>
              <el-tag
                v-for="item in groupPrice"
                :key="item"
                size="small"
                @click="ruleForm.group_price = item"
                style="margin-right: 10px; cursor: pointer"
                >{{ item }}</el-tag
              >
            </div>
          </el-form-item>
          <el-form-item label="拼团时间" prop="start_end_time">
            <el-date-picker
              v-model="ruleForm.start_end_time"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="活动到期，未完成团自动成团">
            <el-switch v-model="ruleForm.group_auto_ok"></el-switch>
          </el-form-item>
          <el-form-item label="拼团支持红包抵用金付款">
            <el-switch v-model="ruleForm.group_credit"></el-switch>
          </el-form-item>
          <el-form-item label="拼团满团后还可继续参团">
            <el-switch v-model="ruleForm.group_ok_continue"></el-switch>
          </el-form-item>
          <el-form-item label="拼团支持退款">
            <el-switch v-model="ruleForm.group_refund"></el-switch>
          </el-form-item>
        </template>
        <!-- prop="course_cover" -->
        <el-form-item label="课程封面">
          <Qiniu
            :isCropper="true"
            :width="525"
            :height="420"
            :imgKey="ruleForm.course_cover"
            @uploadSuccess="uploadSuccess2"
          ></Qiniu>
        </el-form-item>
        <el-form-item label="课程详情">
          图片添加：<Qiniu @uploadSuccess="uploadSuccess"></Qiniu>
          <template
            v-if="ruleForm.course_imgs && ruleForm.course_imgs.length > 0"
          >
            <div
              v-for="(item, index) in ruleForm.course_imgs"
              :key="index"
              class="img-wrap"
            >
              <el-image
                :src="item"
                style="width: 100px"
                :preview-src-list="ruleForm.course_imgs"
              />
              <div class="img-shadow">
                <i
                  v-if="index != 0"
                  class="el-icon el-icon-caret-left"
                  @click="uploadUp(index, item)"
                ></i>
                <i
                  v-if="index != ruleForm.course_imgs.length - 1"
                  class="el-icon el-icon-caret-right"
                  @click="uploadDown(index, item)"
                ></i>
                <i
                  class="el-icon el-icon-delete-solid"
                  @click="uploadRemove(index)"
                ></i>
              </div>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="课程简介">
          <el-input
            size="small"
            type="textarea"
            :rows="2"
            v-model="ruleForm.desp"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="close()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { orgInfo } from "@/api/institutional.js";
import { saveCourse, courseInfo } from "@/api/courseOrder";
import Qiniu from "@/components/qiniu.vue";
import OrgSelect from "@/components/orgSelect.vue";
export default {
  name: "StkAdd",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    typeFlag: {
      // 判断是新建/add 编辑/edit 详情
      type: String,
      default: "add",
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Qiniu,
    OrgSelect,
  },
  data() {
    return {
      catesList: [], // 分类列表
      tagsList: [], // 标签列表
      teachingList: [
        "1对1",
        "家教",
        "2-4人班",
        "4-10人班",
        "10-20人班",
        "20人大班",
      ],
      ageList: [
        "2-8岁",
        "6-12岁",
        "10-16岁",
        "成人",
        "学前",
        "小学",
        "初中",
        "高中",
      ],
      groupNumber: [2, 3, 4],
      groupPrice: [1, 9.9, 19.9],
      // 弹框新建
      org_name: "",
      ruleForm: {
        org_id: "",
        name: "",
        repeat_pay: false, // 是否可重复购买
        org_price: "",
        price: "",
        cates: [],
        teaching_method: "",
        for_age: "",
        mins: "",
        exp_count: "",
        course_imgs: [],
        desp: "",
        // 拼团
        group_switch: false,
        group_people_num: "",
        group_open_price: "",
        group_price: "",
        start_end_time: [],
        course_cover: "",
        group_auto_ok: false,
        group_credit: false,
        group_ok_continue: false,
        group_refund: false,
      },
      rules: {
        org_id: [{ required: true, message: "请选择机构", trigger: "change" }],
        name: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
          { min: 1, max: 20, message: "最多20个字符", trigger: "blur" },
        ],
        repeat_pay: [
          { required: true, message: "请选择是否购买", trigger: "change" },
        ],
        org_price: [
          { required: true, message: "请输入划线价", trigger: "blur" },
        ],
        price: [{ required: true, message: "请输入课程价格", trigger: "blur" }],
        cates: [{ required: true, message: "请选择分类", trigger: "change" }],
        teaching_method: [
          { required: true, message: "请输入授课方式", trigger: "blur" },
        ],
        for_age: [
          { required: true, message: "请输入适用年龄", trigger: "blur" },
        ],
        mins: [{ required: true, message: "请输入体验时长", trigger: "blur" }],
        exp_count: [
          { required: true, message: "请输入体验次数", trigger: "blur" },
        ],
        group_people_num: [
          { required: true, message: "请输入成团人数", trigger: "blur" },
        ],
        group_open_price: [
          { required: true, message: "请输入开团价格", trigger: "blur" },
        ],
        group_price: [
          { required: true, message: "请输入成团价格", trigger: "blur" },
        ],
        start_end_time: [
          { required: true, message: "请输选择拼团时间", trigger: "blur" },
        ],
        course_cover: [
          { required: true, message: "请上传课程封面", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    // 选中机构
    selectOrg(org_id) {
      this.ruleForm.org_id = org_id;
      this.ruleForm["cates"] = [];
      this.$set(this.ruleForm, "tags", []);
      this.getallCate(org_id);
    },
    // 获取分类和标签
    getallCate(org_id, res) {
      orgInfo({ org_id })
        .then((resList) => {
          if (resList.code == 200) {
            this.tagsList = resList.data["tags"]
              ? resList.data["tags"].split(",").map((item) => {
                  return { id: item, name: item };
                })
              : [];
            this.catesList = resList.data["cates"]
              ? resList.data["cates"].split(",").map((item) => {
                  return { id: item, name: item };
                })
              : [];
            if (res) {
              // 分类和标签要写在分类和标签列表获取成功之后
              this.ruleForm["cates"] = res.data["cates"]
                ? res.data["cates"].split(",")
                : [];
              this.$set(
                this.ruleForm,
                "tags",
                res.data["tags"] ? res.data["tags"].split(",") : []
              );
            }
          }

          console.log("标签", this.ruleForm["tags"]);
          console.log("全部", this.tagsList);
        })
        .catch((rej) => {
          console.log(rej);
        });
    },
    // 获取数据
    getInfo(id) {
      courseInfo({
        id: id || this.id,
      })
        .then((res) => {
          let switchList = [
            "repeat_pay",
            "group_switch",
            "group_auto_ok",
            "group_credit",
            "group_ok_continue",
            "group_refund",
          ];
          for (let item in this.ruleForm) {
            if (item == "course_imgs") {
              // 后端返回null时前端push进入会报错兼容一下
              this.ruleForm[item] = res.data[item] ? res.data[item] : [];
            } else if (switchList.indexOf(item) > -1) {
              // 后端返回null时前端push进入会报错兼容一下
              this.ruleForm[item] = Number(res.data[item]) ? true : false;
            } else if (item == "start_end_time") {
              // 时间单独处理
              this.ruleForm[item] =
                res.data.group_start_at && res.data.group_end_at
                  ? [
                      res.data.group_start_at * 1000,
                      res.data.group_end_at * 1000,
                    ]
                  : [];
            } else if (item == "tags") {
              this.$set(this.ruleForm, "tags", []);
            } else {
              this.ruleForm[item] = res.data[item];
            }
          }
          this.org_name = res.data["org_name"]; // 机构名不能传
          this.getallCate(res.data.org_id, res); // 分类和标签要写在分类和标签列表获取成功之后
        })
        .catch((err) => {
          console.log(err, 222);
        });
      this.$emit("close");
    },
    // 创建
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    save() {
      let params = { ...this.ruleForm };
      params.repeat_pay = Number(this.ruleForm.repeat_pay);
      params.group_switch = Number(this.ruleForm.group_switch);
      if (this.ruleForm.group_switch) {
        params.group_price = this.ruleForm.group_price / 1;
        params.group_open_price = this.ruleForm.group_open_price / 1;
        params.group_people_num = this.ruleForm.group_people_num / 1;
        params.group_auto_ok = Number(this.ruleForm.group_auto_ok);
        params.group_refund = Number(this.ruleForm.group_refund);
        params.group_credit = Number(this.ruleForm.group_credit);
        params.group_ok_continue = Number(this.ruleForm.group_ok_continue);
        params.group_start_at =
          new Date(this.ruleForm.start_end_time[0]).getTime() / 1000;
        params.group_end_at =
          new Date(this.ruleForm.start_end_time[1]).getTime() / 1000;
      } else {
        delete params.group_people_num;
        delete params.group_open_price;
        delete params.group_price;
        // delete params.course_cover
        delete params.group_auto_ok;
        delete params.group_credit;
        delete params.group_ok_continue;
        delete params.group_refund;
      }
      delete params.start_end_time;
      if (this.typeFlag == "edit") {
        params.id = this.id;
      }
      saveCourse(params)
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.close();
          this.typeFlag == "edit"
            ? this.$emit("getList")
            : this.$emit("searchList"); // 编辑时分页不到第一页
        })
        .catch((err) => {
          this.$emit("close");
          console.log(err, 222);
        });
    },
    // 关闭
    close() {
      this.$emit("close");
      this.catesList = [];
      this.tagsList = [];
      this.ruleForm = {
        org_id: "",
        name: "",
        repeat_pay: false, // 是否可重复购买
        org_price: "",
        price: "",
        cates: [],
        tags: [],
        teaching_method: "",
        for_age: "",
        mins: "",
        exp_count: "",
        course_imgs: [],
        desp: "",
        // 拼团
        group_switch: false,
        group_people_num: "",
        group_open_price: "",
        group_price: "",
        start_end_time: [],
        course_cover: "",
        group_auto_ok: false,
        group_credit: false,
        group_ok_continue: false,
        group_refund: false,
      };
    },
    // 上传图片成功
    uploadSuccess(res) {
      this.ruleForm.course_imgs.push(this.$constant.qnUrl + "/" + res.key);
    },
    uploadSuccess2(res) {
      this.ruleForm.course_cover = this.$constant.qnUrl + "/" + res.key;
    },
    // 上传图片删除
    uploadRemove(i) {
      this.ruleForm.course_imgs.splice(i, 1);
    },
    // 上传图片上移
    uploadUp(i, item) {
      this.ruleForm.course_imgs.splice(i - 1, 0, item);
      this.ruleForm.course_imgs.splice(i + 1, 1);
    },
    // 上传图片下移
    uploadDown(i, item) {
      this.ruleForm.course_imgs.splice(i + 2, 0, item);
      this.ruleForm.course_imgs.splice(i, 1);
    },
  },
};
</script>
<style lang="less" scoped>
.img-wrap {
  position: relative;
  display: inline-block;
  margin: 0 20px 20px 0;
}
.img-shadow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.el-icon {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
</style>